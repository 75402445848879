<template>
  <div>
    <div style="margin: 10px 180px;">
      <el-radio-group v-model="dictDataArray.run.use">
        <el-radio :label="1">{{$t('public.Enable')}}</el-radio>
        <el-radio :label="0">{{$t('public.Forbidden')}}</el-radio>
      </el-radio-group>
    </div>
    <span style="margin-left: 122px;color:#606266;font-size: 14px;">{{$t('System.AndroidConfig.RunSetup')}}</span>
    <el-tooltip placement="right" effect="light" style="margin-left: 8px; margin-top: 12px">
      <div slot="content" style="color: #807e7e">{{$t('System.AndroidConfig.RunningStatus')}}</div>
      <img src="@/assets/question.png" alt="" srcset="" />
    </el-tooltip>
    <!-- <el-switch active-value="1" inactive-value="0" v-model="dictDataArray.run.use" style="padding-left: 15px; margin-top: -8px"></el-switch> -->
    <div style="margin-top:10px;">
      <el-checkbox :disabled="dictDataArray.run.use == 0" true-label="1" false-label="0" v-model="dictDataArray.run.isDefault" style="padding-left: 183px">
        <span>{{$t('System.AndroidConfig.CheckBoxmessage1')}}</span>
      </el-checkbox>
    </div>
    <div style="margin:10px 0;">
      <el-checkbox :disabled="dictDataArray.run.use == 0" true-label="1" false-label="0" v-model="dictDataArray.run.isRunAlways" style="padding-left: 183px">
        <span>{{$t('System.AndroidConfig.CheckBoxmessage2')}}</span>
      </el-checkbox>
    </div>
    <div>
      <el-checkbox :disabled="dictDataArray.run.use == 0" true-label="1" false-label="0" v-model="dictDataArray.run.allowUninstall" style="padding-left: 183px">
        <span>{{$t('System.AndroidConfig.CheckBoxmessage3')}}</span>
      </el-checkbox>
    </div>
    <!-- <p style="padding-left: 110px;font-size: 14px;">数据防泄漏：</p>
    <el-form ref="form" label-width="110px" class="demo-ruleForm" style="padding: 0 120px">
      <el-form-item label="界面水印">
        <el-tooltip placement="right" effect="light">
          <div slot="content" style="color: #807e7e">
            应用界面显示水印，可对信息来源<br />追溯
          </div>
          <img src="@/assets/question.png" alt="" srcset="" style="padding-left: 8px" />
        </el-tooltip>
        <el-switch active-value="1" inactive-value="0" style="padding-left: 15px" v-model="dictDataArray.water.use"></el-switch>
      </el-form-item>
      <div style="display: flex; padding: 0 40px" v-if="dictDataArray.water.use == 1">
        <div style="width: 300px; margin: 0 10px 0 110px">
          <el-table ref="multipleTable" :data="dictData" stripe size="mini" tooltip-effect="dark" class="sort_table" :header-cell-style="{ background: '#f7f8fa' }" style="width: 100%">
            <el-table-column header-align="left" align="left" prop="name" label="名称" width="120" show-overflow-tooltip>
            </el-table-column>
            <el-table-column header-align="left" align="left" prop="" label="操作" show-overflow-tooltip>
              <img @click="delwater" src="@/images/icon_del.png" alt="" style="
                  width: 15px;
                  height: 15px;
                  margin-left: 5px;
                  cursor: pointer;
                " />
            </el-table-column>
          </el-table>
        </div>
        <el-button style="border-radius: 18px; width: 100px; height: 35px" size="small" type="primary" @click="responseBtn" icon="el-icon-plus">界面水印</el-button>
      </div>
      <el-form-item label="截屏/录屏保护">
        <el-tooltip placement="right" effect="light" style="margin-left: 8px; margin-top: 12px">
          <div slot="content" style="color: #807e7e">
            防止通过截屏或录屏操作导致应<br />用数据泄露
          </div>
          <img src="@/assets/question.png" alt="" srcset="" />
        </el-tooltip>
        <el-switch active-value="1" inactive-value="0" v-model="dictDataArray.screenshot.use" style="padding-left: 15px; margin-top: -8px"></el-switch>
      </el-form-item>
      <div v-if="dictDataArray.screenshot.use == 1" style="margin-left: 105px">
        <el-checkbox :disabled="true" true-label="1" false-label="0" v-model="checkbox" style="padding-left: 45px">
          <span>禁止系统对此应用的截屏/录屏操作</span>
        </el-checkbox>
      </div>
    </el-form> -->
    <el-button style="width:80px;margin:20px 0 0 270px;" @click="saveSubmit" size="small" type="primary">保存</el-button>
    <Eldialog :num="'470px'" :title="'选择界面水印'" :btnTitle="'加入'" :flagbtn="true" :cancelBtn="true" :dialogVisible="statusVisible" @handleClose="handleClose" :determineBtn="true" @determine="determine">
      <el-input v-model="input" @keyup.enter.native="onSbumit" size="small" style="width: 180px; margin-bottom: 10px" placeholder="请输入名称"></el-input>
      <el-button type="primary" @click="onSbumit" size="small" style="margin-left: 3px">查询</el-button>
      <el-table size="mini" stripe ref="multipleTable" :data="tableDataNmae" class="sort_table" tooltip-effect="dark" style="width: 97%" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" :default-sort="{
          prop: 'updateTime',
          order: 'descending',
        }">
        <el-table-column header-align="center" align="center" width="55">
          <template v-slot="{ row }">
            <el-radio-group @change="changeEvent(row)" v-model="radio">
              <el-radio :label="row.id">{{ }}</el-radio>
            </el-radio-group>
          </template>
        </el-table-column>
        <el-table-column :show-overflow-tooltip="true" prop="name" label="名称" width="150">
          <template slot-scope="scope">
            <div>
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="groupFullName" label="部门" width="150" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.groupFullName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="修改时间" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span v-if="scope.row.time == ''"></span><span class="detaildesc" v-else>
              {{ scope.row.updateTime | dateformat }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; width: 565px; justify-content: space-between">
        <p style="margin-top: 15px">
          共<b>{{ this.dataTolCount }}</b>条记录
        </p>
      </div>
    </Eldialog>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>

<script>
import dialogInfo from '@/components/promptMessage/index'
import Eldialog from '@/components/elDialog' //详情弹框
export default {
  components: {
    Eldialog,
    dialogInfo
  },
  data() {
    return {
      titleInfo: '',
      visibaelFlag: false,
      dictDataArray: {
        run: {
          use: 0,
          isRunAlways: 0,
          isDefault: 0,
          allowUninstall: 0
        },
        water: {
          use: '0',
          id: 1,
          name: '系统配置水印'
        },
        screenshot: {
          use: '1'
        }
      },
      checkbox: '1',
      waterData: [],
      permission: {
        watermark: {
          use: '0',
          waters: []
        },
        encryptAndDecrypt: {
          use: '0'
        },
        safeKeyboard: {
          use: '0'
        },
        screen: {
          use: '0',
          enable: '0',
          collect: '0',
          action: '0'
        },
        sysShare: {
          use: '0',
          enable: '0',
          collect: '0',
          action: '0'
        },
        copyPaste: {
          use: '0',
          enable: '0',
          collect: '0',
          action: '0'
        }
      },

      radio4: '1',
      radio: '',
      radio1: '', //单选
      input: '', //弹框查询
      dataTolCount: 0, //初始化总数为0
      statusVisible: false, //点击相应动作弹出详情框
      jietu: false, //开关是否显示（截图/录屏）
      sysflag: false, //开关是否显示（系统分享
      copyflag: false, //开关是否显示(复制/粘贴)
      copydisable: false, //开关复制粘贴的 单选
      checked1: '', //多选1
      checked2: '', //多选2
      checked: false,
      copychecked: false,
      spanflag: false, //多选1选中后面提示语显示
      more1: '', //多选（系统分享
      more2: '', //多选（系统分享
      form: [
        {
          delivery1: false,
          delivery2: false,
          delivery3: false,
          delivery4: false,
          delivery5: false,
          delivery6: false
        }
      ],
      dictData: [],
      dictDataItem: null,
      tableDataNmae: [], //列表界面水印响应数组
      responsedata: {
        type: '20',
        action: 'edit',
        currentPage: 1, //pageNumber
        pageSize: 999, //rowNumber
        orderColume: 'updateTime',
        orderRule: 'DESC',
        name: ''
      },
      // 截屏/录屏的disabled
      disabled: false
    }
  },
  created() {
    this.searchList()
  },
  methods: {
    async saveSubmit() {
      // if (this.dictDataArray.water.use == 1) {
      //   if (this.dictData.length == 0) {
      //     this.$message({
      //       message: '请选择界面水印',
      //       type: 'error',
      //       offset: 100
      //     })
      //     return
      //   }
      // }
      if(this.dictDataArray.run.use ==1){
        if(this.dictDataArray.run.isDefault == 0 && this.dictDataArray.run.isRunAlways == 0 && this.dictDataArray.run.allowUninstall== 0 ){
          this.$message({
          message: '至少配置一项',
          type: 'error',
          offset: 100
         })
         return false;
        }
      }
      let params = {
        run: {
          use: this.dictDataArray.run.use * 1,
          isRunAlways: this.dictDataArray.run.isRunAlways * 1,
          isDefault: this.dictDataArray.run.isDefault * 1,
          allowUninstall: this.dictDataArray.run.allowUninstall * 1
        },
        water: {
          use: this.dictDataArray.water.use * 1,
          id:
            this.dictDataArray.water.use * 1 == 1
              ? this.dictDataArray.water.id
              : '',
          name:
            this.dictDataArray.water.use * 1 == 1
              ? this.dictDataArray.water.name
              : ''
        },
        screenshot: {
          use: this.dictDataArray.screenshot.use * 1
        }
      }
      const res = await this.$axios.post(
        '/httpServe/android/safePolicy/update',
        params,
        true
      )
      if (res.code == 200) {
        // this.titleInfo = "保存成功"
        // this.visibaelFlag = true
        this.$message({
          message: '保存成功',
          type: 'success',
          offset: 100
        })
      }
    },
    async searchList() {
      const res = await this.$axios.post(
        '/httpServe/android/safePolicy/selectOne',
        {},
        true
      )
      this.dictDataArray = {
        run: {
          use: res.data.run.use,
          isRunAlways:
            res.data.run.use == 1 ? res.data.run.isRunAlways + '' : '',
          isDefault: res.data.run.use == 1 ? res.data.run.isDefault + '' : '',
          allowUninstall:
            res.data.run.use == 1 ? res.data.run.allowUninstall + '' : ''
        },
        water: {
          use: res.data.water.use + '',
          id: res.data.water.id,
          name: res.data.water.name
        },
        screenshot: {
          use: res.data.screenshot.use + ''
        }
      }
      if (res.data.water.use == 1) {
        this.dictData.push({
        name:res.data.water.name,
        id:res.data.water.id
      })
      this.radio = res.data.water.id
      }
    },
    async onSbumit() {
      var param = {
        currentPage: this.responsedata.currentPage,
        pageSize: this.responsedata.pageSize,
        orderColume: this.responsedata.orderColume,
        orderRule: this.responsedata.orderRule,
        name: this.input
      }
      console.log(param, 'param')
      const res = await this.$axios.post(
        '/httpServe/waterConfig/getDataInfo',
        param,
        true
      )
      this.tableDataNmae = res.data.content //列表数据
      this.dataTolCount = res.data.total //列表总数
    },
    // 界面水印弹框 里面  加入 按钮  点击 加入按钮才加入
    joinType() {
      this.dictData = []
      this.dictData.push({ name: this.waterData.name, id: this.waterData.id })
      this.dictDataArray.water.id = this.waterData.id
      this.dictDataArray.water.name = this.waterData.name
    },
    delwater() {
      this.permission.watermark.waters = this.dictData = [];
      this.radio = ""
      this.$emit("water", this.permission);
    },
    // 界面水印change改变事件
    changeEvent(val) {
      this.waterData = val
    },
    // 界面水印的响应动作按钮
    responseBtn() {
      this.statusVisible = true //点击出现详情弹框
      this.theResponse()
    },
    // 响应界面水印--列表
    async theResponse() {
      var param = {
        currentPage: this.responsedata.currentPage,
        pageSize: this.responsedata.pageSize,
        orderColume: this.responsedata.orderColume,
        orderRule: this.responsedata.orderRule,
        name: this.input
      }
      const res = await this.$axios.post(
        '/httpServe/waterConfig/getDataInfo',
        param,
        true
      )
      this.tableDataNmae = res.data.content //列表数据
      this.dataTolCount = res.data.total //列表总数
    },
    determine() {
      this.joinType()
      this.statusVisible = false
    },
    // determine(){
    //   this.visibaelFlag = false
    // },
    // 弹框点击关闭事件
    handleClose() {
      this.visibaelFlag = false
      this.statusVisible = false //点击出现详情弹框
      this.input = '' //选择界面水印的条件清空
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__empty-text {
  width: 100%;
}
::v-deep .el-form-item {
  margin-bottom: 0px;
}
</style>
