<template>
  <div>
    <div class="box" style="width: 100%;height: 100%;padding-left: 30px;"> 
        <el-form ref="form" label-width="150px" >
            <el-form-item :label="$t('System.IosConfig.SecureDesktops.Label')">
              <div style="display: flex;width: 767px;margin-left: 19px;">
              <el-checkbox-group v-model="defaultApp" @change="changCheckbox">
                  <el-checkbox style="width: 100px;" v-for="(item,index) in CheckboxList" :key="index" :label="item.label">{{ $t(`System.AndroidConfig.SecureDesktops.${item.value}`) }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </el-form-item>
            <el-form-item :label="$t('System.AndroidConfig.otherApp')"> 
                <div style="display: flex; margin-left: 6px">
                    <div style="width: 537px; margin: 8px 10px 0 9px">
                        <el-table ref="multipleTable" :data="tableData" stripe size="mini" tooltip-effect="dark"  class="tableClass"  :row-style="iRowStyle" :cell-style="iCellStyle" :header-row-style="iHeaderRowStyle"  :header-cell-style="{ background: '#f7f8fa', color: '#606266', padding:'0px'}" style="width: 100%">
                        <el-table-column header-align="left" align="left" prop="name" :label="$t('System.AndroidConfig.appName')" width="200" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column header-align="left" align="left" prop="identifier" :label="$t('System.AndroidConfig.appID')" width="200" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column header-align="left" align="left" prop="" :label="$t('public.Controls')" show-overflow-tooltip>
                            <template v-slot="{ row }">
                                <!-- @/images/icon_del.png -->
                            <img @click="delapp(row)" src="@/images/icon_del.png" alt="" style="
                            width: 15px;
                                height: 15px;
                                margin-left: 5px;
                                cursor: pointer;
                            " />
                            </template>

                            <!-- </template> -->
                        </el-table-column>
                        </el-table>
                    </div>
                    <div>
                        <el-button size="mini" type="primary" @click="addApp" icon="el-icon-plus">{{$t('System.AndroidConfig.Application')}}</el-button>
                    </div>

                </div> 
            </el-form-item> 
        </el-form> 
        <div>
            <el-button style="width: 80px; margin-left: 169px;margin-bottom: 85px;margin-top: 22px;" @click="saveSubmit" size="small" type="primary">{{$t('public.Save')}}
            </el-button>
        </div>
    </div>
    <Eldialog :num="'434px'" :width="'915px'" :title="$t('System.AndroidConfig.Title')" :dialogVisible="statusVisible" @handleClose="handleClose" :btnTitle="$t('public.Join')" :flagbtn="true" :cancelBtn="true" :determineBtn="true" @determine="joinType">
      <div>
        <el-input v-model="ipt" @input="(e) => (ipt = validForbid(e))" @keyup.enter.native="onsearch" size="small" style="width: 180px; margin-bottom: 10px" :placeholder="$t('System.AndroidConfig.placeholder')"></el-input>
        <el-button @click="onsearch" type="primary" size="small" style="margin-left: 3px">{{$t('public.Inquire')}}</el-button>
        <el-table size="mini" stripe ref="multipleTables" :data="detailData" :row-key="getRowKeys" class="sort_table" tooltip-effect="dark" style="width: 98%" :default-sort="{ prop: 'lastUpdateTime', order: 'descending' }" :header-cell-style="{ background: '#f7f8fa', color: '#606266' }" @selection-change="handleSelectionChange">
          <el-table-column header-align="center" align="center" type="selection" width="60" :reserve-selection="true">
          </el-table-column>
          <el-table-column prop="name" :label="$t('public.ApplicationName')" width="180" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="identifier" :label="$t('public.ApplicationID')" width="180" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="versionName" :label="$t('public.Version')" width="180" :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column prop="lastUpdateTime" :label="$t('public.ModificationTime')" :show-overflow-tooltip="true" width="180">
            <template v-slot="{ row }">
              {{ row.lastUpdateTime | dateformat }}
            </template>
          </el-table-column>
        </el-table> 
      </div>
      <pagination :page="responseData.currentPage" :limit="responseData.pageSize" :total="responseData.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList" />
    </Eldialog>
  </div>
</template>
<script>
import pagination from '@/components/pagination/page.vue' //分页
import Eldialog from '@/components/elDialog' //详情弹框
import { Checkbox } from 'element-ui';
export default {
    components: {
    Eldialog, 
    pagination
  },
  data() {
    return { 
      CheckboxList:[
        {
          label:'message',//消息通知
          value:"消息通知",
          key:0
        },
        {
          label:'document',//文档内容
          value:"文档内容",
          key:1,
        },
        {
          label:'store',//应用商店
          value:"应用商店",
          key:2,
        },
        {
          label:'help',//使用帮助
          value:"使用帮助",
          key:3,
        }, 
        {
          label:'settings',//个人设置
          value:"个人设置",
          key:4,
        },
        {
          label:'policy',//安全策略
          value:"安全策略",
          key:5,
        },
        {
          label:'score',//健康评分
          value:"健康评分",
          key:6,
        },
        {
          label:'access',//安全访问
          value:"安全访问",
          key:7,
        },
        {
          label:'browser',//安全浏览器
          value:"安全浏览器",
          key:8,
        },
        {
          label:'switchX',//切换桌面
          value:"切换桌面",
          key:9,
        }, 
        
      ],
      defaultApp:[],//内置应用 
      tableData: [],//安全桌面tab页里的  列表-数组
      multipleSelection: [],
      geofences:[],//添加进去的id
      detailData: [],// 点击-安全桌面tab页里的  按钮 '应用'  里的 弹框列表-数组
      statusVisible: false, //应用按钮响应动作显示
      ipt: '',
      // 响应动的参数
      responseData: {
        total: 0,
        currentPage: 1, //pageNumber
        pageSize: 10, //rowNumber
        orderColume: 'lastUpdateTime',
        orderRule: 'DESC'
      }, 
      otherApp: [],
    };
  },
  mounted() {
    this.searchList()
  },
  methods:{
    changCheckbox(val){ 
        let dataAray = []
        val.forEach((i,index)=>{
          let obj = this.CheckboxList.find((item)=>{return item.label ==i})
          dataAray.push(obj)
        })
        
        console.log(dataAray);
        
        dataAray.sort((a,b)=>a.key-b.key)
        console.log(dataAray,'.....改变后...');
        let arr =[]
        dataAray.forEach((item,index)=>{
          arr.push(item.label)
        })
        this.defaultApp = arr
        console.log(this.defaultApp,'this.defaultApp');
    },
    //添加应用
    addApp(){
        this.ipt = ''
        this.statusVisible = true
        this.responseData.currentPage = 1 
        this.getDatalist()//调取列表 
    },
    // 搜索  弹框
    onsearch() {
      // onsearch
      this.getDatalist()
    },
    // 删除
    delapp(val) {  
      var del_result=this.tableData.filter(item => item.id != val.id) 
      this.tableData=del_result
      this.otherApp=del_result 
     },
     // 查询
    async searchList() { 
      var param = {}
      const res = await this.$axios.post(
        '/httpServe/android/safeDesk/selectOne',
        param,
        true
      ) 
      
      if(res.data){
        this.defaultApp= res.data.defaultApp
        this.tableData = res.data.otherApp  
        if(this.tableData){
          this.otherApp = []
          this.tableData.forEach((item,index)=>{
                this.otherApp.push({
                  id: item.id,
                  name: item.name,
                  identifier: item.identifier, 
                })
          })

          } 
      }
    
    }, 
    // 列表
    async getDatalist() {
      if (this.ipt.length > 0) {
        this.responseData.currentPage = 1
      }
      var param = {
        searchName: this.ipt,
        osType: 20,
        currentPage: this.responseData.currentPage,
        pageSize: this.responseData.pageSize,
        orderColume: this.responseData.orderColume,
        orderRule: this.responseData.orderRule
      }
      const res = await this.$axios.post(
        '/httpServe/android/appLib/getDataInfo',
        param,
        true
      )
      this.detailData = res.data.content //列表数据 
      // this.dataTolCount = res.data.total; //列表总数
      this.responseData.total = res.data.total //列表总数
      this.$nextTick(() => {
        this.detailData.forEach((item, index) => {
          this.tableData.forEach((i, j) => {
            if (item.id == i.id) { 
              // this.$refs.multipleTables.toggleRowSelection(item, true)
            }
          })
        })
        this.otherApp = this.tableData 
      })
       
    }, 
    async save(){
        var param = {
        defaultApp: this.defaultApp,
        otherApp: this.otherApp, 
      } 
      const res = await this.$axios.post(
        '/httpServe/android/safeDesk/update',
        param,
        true
      ) 
      if (res.data == 1) { 
        this.$message({
          message: this.$t('public.SuccessfullySaved'),
          type: 'success',
          offset: 100
        })
      }
    },
    //保存
    saveSubmit(){
     this.save()
    },
    // 加入 弹框
    joinType() { 
      // this.tableData=[]
      this.multipleSelection.forEach((item, index) => { 
        this.tableData.push({
          id: item.id,
          name: item.name,
          identifier: item.identifier, 
        })
      }) 
      var result = []
      var obj = {}
      for (var i = 0; i < this.tableData.length; i++) {
        if (!obj[this.tableData[i].identifier]) {
          result.push(this.tableData[i])
          obj[this.tableData[i].identifier] = true
        }
      }
      this.tableData = result 
      this.otherApp = result
      this.$refs.multipleTables.clearSelection() 
      this.statusVisible = false 
    },
    //弹框中列表 change的改变事件
    handleSelectionChange(val) {
      this.multipleSelection = [...val]
    },
    // 弹框点X击关闭事件
    handleClose() {
      this.ipt = ''
      this.$refs.multipleTables.clearSelection() 
      this.statusVisible = false //点击出现详情弹框
      this.responseData.currentPage = 1
      
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.responseData.currentPage = page
      this.responseData.pageSize = limit
      // 调用查询方法
      this.getDatalist()
    },
    getRowKeys(row) {
      return row.id
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return 'height:30px'
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'padding:3px 0px'
    },
    
  }
};
</script>

<style>
</style>