<template>
  <div>
    <!-- 签名证书 -->
    <el-form
      ref="form"
      :model="form"
      label-width="80px"
      class="demo-ruleForm"
      style="padding: 0 80px"
    >
      <el-form-item label="证书名称："> </el-form-item>
      <el-form-item label="证书简介："> </el-form-item>
      <el-form-item label="下载证书："> </el-form-item>
      <el-form-item label="上传证书：">
        <el-input
          style="width: 200px; margin: 0 10px 0 0"
          size="small"
        ></el-input>
        <el-upload
          action="#"
          :auto-upload="false"
          :show-file-list="false"
          :on-success="handleAvatarSuccess1"
          class="el-button mar-class-8"
          style="padding: 0; border: none"
          :on-change="changeUpload"
          multiple
          accept=".apk"
        >
          <el-button
            size="small"
            style="
              width: 98px;
              position: relative;
              background: #e5981e;
              border: none;
            "
          >
            <span
              style="
                cursor: pointer;
                margin-top: 5px;
                position: absolute;
                top: 1px;
              "
            >
              <img src="@/images/../images/look.png" alt="" />
            </span>
            <span style="padding-left: 18px; color: white">选择文件</span>
          </el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="证书密码：">
        <el-input
          style="width: 200px; margin: 0 10px 0 0"
          size="small"
        ></el-input>
        <span style="color: #807e7e">如密码无变化，保持该项为空</span>
      </el-form-item>
      <el-form-item label="证书别名：">
        <el-input
          style="width: 200px; margin: 0 10px 0 0"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="别名密码：">
        <el-input
          style="width: 200px; margin: 0 10px 0 0"
          size="small"
        ></el-input>
        <span style="color: #807e7e">如密码无变化，保持该项为空</span>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        // 系统权限限制
        use: false,
      },
      dataArray:{}
    };
  },
  created(){
    this.selectSearch()
  },
  methods: {
    async selectSearch() {
      const res = await this.$axios.post(
        "/httpManageExport/android/safePolicy/selectOne",
        {}
      );
      this.dataArray = res.data.data;
      console.log(res);
    },
    //文件上传接口
    async changeUpload(file) {
      console.log(file.progress);
      this.percentage = 0;
      this.flagStatus = true;
      if (file.size / (1024 * 1024) > 500) {
        // 限制文件大小
        this.$message.warning(`当前限制文件大小不能大于500M`);
        return false;
      }
      let suffix = this.getFileType(file.name); //获取文件后缀名
      let suffixArray = ["apk"]; //限制的文件类型，根据情况自己定义
      if (suffixArray.indexOf(suffix) === -1) {
        this.$message({
          message: "文件格式错误",
          type: "error",
          duration: 2000,
        });
      } else {
        const form = new FormData();
        // 文件对象
        form.append("files", file.raw);
        form.append("type", "protected");
        form.append("fileType", "apk");
        form.append("moduleName", "app");
        // const res = await this.$axios.upload("/httpServe/sysFile/fileUp", form, false);
        const res = await this.$axios.upload(
          "/httpServe/sysFile/fileUp",
          form,
          this.updateProgress,
          true
        );
        this.path = res.data.data[0];
        // console.log(file.name)
        this.$nextTick(() => {
          // this.ruleForm.appName = ;
          this.$set(this.form, "appName", file.name);
        });
      }
    },
    updateProgress(e) {
       //e为回调回来的参数 通过进行和total的值来进行进度
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf(".");
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase();
      } else {
        return "";
      }
    },
    handleAvatarSuccess1() {},
    test(val) {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 0px !important;
}
::v-deep .el-form-item__label {
  font-size: 12px;
}
</style>