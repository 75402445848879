<template>
  <div>
    <div class="box">
      <p style="padding-left: 122px; padding-top: 30px;font-size: 14px;">崩溃监测：</p>
      <div>
        <el-form ref="form" :model="form" label-width="151px" class="demo-ruleForm" style="padding: 0 120px">
          <el-form-item label="记录崩溃信息：">
            <el-tooltip placement="right" effect="light">
              <div slot="content" style="color: #807e7e">
                监测应用崩溃情况，通过自动化分<br />析建立线上故障感知能力，提升应<br />用使用体验。若沙箱应用进行崩溃<br />监测，则将崩溃的信息上传至服务<br />器，并在崩溃详情列表展示数据泄露
              </div>
              <img src="@/assets/question.png" alt="" srcset="" style="padding-left: 8px" />
            </el-tooltip>
            <el-switch style="padding-left: 15px" active-value="1" inactive-value="0" v-model="form.use"></el-switch>
          </el-form-item>
        </el-form>
        <el-button style="width:80px;margin:20px 0 0 140px;" @click="saveSubmit" size="small" type="primary">保存
        </el-button>
      </div>
      <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="determine" :titleInfo="titleInfo" :width="'240px'" />
    </div>
  </div>
</template>

<script>
import dialogInfo from '@/components/promptMessage/index'
export default {
  components: {
    dialogInfo
  },
  data() {
    return {
      titleInfo: '',
      visibaelFlag: false,
      form: {
        // 系统权限限制
        use: false
      }
    }
  },
  created() {
    this.searchList()
  },
  methods: {
    // 弹框点击关闭事件
    handleClose() {
      this.visibaelFlag = false
    },
    determine() {
      this.visibaelFlag = false
    },
    async saveSubmit() {
      let params = {
        use: this.form.use * 1
      }
      const res = await this.$axios.post(
        '/httpServe/android/crash/update',
        params,
        true
      )
      if (res.code == 200) {
        // this.titleInfo = "保存成功"
        // this.visibaelFlag = true
        this.$message({
          message: '保存成功',
          type: 'success',
          offset: 100
        })
      }
    },
    async searchList() {
      const res = await this.$axios.post(
        '/httpServe/android/crash/selectOne',
        {},
        true
      )
      this.form.use = res.data.use + ''
    }
  }
}
</script>

<style>
</style>
