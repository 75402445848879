<template>
  <div class="compliance">
    <!-- <h4 class="breadtitle">安卓配置</h4> -->
    <navi-gation style="font-size: 16px" />
    <div>
      <el-tabs v-model="activeName" @tab-click="handleLoad" type="card" class="tabs">
        <el-tab-pane :label="$t('System.AndroidConfig.Application')" name="first">
          <application :key="timer1"/>
        </el-tab-pane>
        <!-- <el-tab-pane label="签名证书" name="se">
          <SigningCertificate/>
        </el-tab-pane> -->
        <el-tab-pane :label="$t('System.AndroidConfig.RunSetup')" name="sd">
          <TheSecuritypolicy :key="timer2"/>
        </el-tab-pane>
        <!-- <el-tab-pane label="威胁监测" name="sf">
          <ThreatMonitoring/>
        </el-tab-pane>
        <el-tab-pane label="崩溃监测" name="sh">
          <CollapseMonitoring/>
        </el-tab-pane> -->
        <el-tab-pane :label="$t('System.AndroidConfig.Configuration')" name="sk">
          <CollectConfiguration :key="timer3"/>
        </el-tab-pane>
        <el-tab-pane :label="$t('System.IosConfig.SecureDesktop')" name="SecureDesktop">
          <SecureDesktop :key="timer4"/>
        </el-tab-pane>
      </el-tabs>
    </div>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import application from "./components/androidComponents/application.vue" //应用
import CollectConfiguration from "./components/androidComponents/CollectConfiguration.vue" //采集配置
import SecureDesktop from "./components/androidComponents/SecureDesktop.vue"//安全桌面
import SigningCertificate from "./components/androidComponents/SigningCertificate.vue" //签名证书
import TheSecuritypolicy from "./components/androidComponents/TheSecuritypolicy.vue" //安全策略
import CollapseMonitoring from "./components/androidComponents/CollapseMonitoring.vue" //崩溃监测
import ThreatMonitoring from "./components/androidComponents/ThreatMonitoring.vue" //威胁监测
import dialogInfo from "@/components/promptMessage/index";
import naviGation from "@/components/hearder/index";
export default {
  components: { dialogInfo, naviGation,application,SigningCertificate,TheSecuritypolicy,CollapseMonitoring,SecureDesktop,ThreatMonitoring,CollectConfiguration},
  name: "compliance",
  data() {
    return {
      list:[],
      visibaelFlag: false, //提示消息默认--关闭
      titleInfo: "", // 提示框的提示语
      activeName: "first",
      disabled: true,
      timer1:"",
      timer2:"",
      timer3:"",
      timer4:"",

    };
  },

  methods: {
      handleStartDateChange(){

      },
      handleClose(){
          this.visibaelFlag = false
      },
       // tab页切换页面刷新
      handleLoad(data) {
      if (data.name == "first") {
        this.timer1 = new Date().getTime()
      } 
      if (data.name == "sd") {
        this.timer2 = new Date().getTime()
      }
       if (data.name == "sk") {
        this.timer3 = new Date().getTime()
      }
      if (data.name == "SecureDesktop") {
        this.timer4 = new Date().getTime()
      }
    }
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: Microsoft YaHei;
  font-size: 12px;
}
// ::v-deep .el-form-item {
//   margin-bottom: 17px !important;
// }
// ::v-deep .el-form-item__error{
//   padding-top:1px !important;
// }
.editbox {
  padding-top: 15px;
  padding-bottom: 25px;
  margin: 0 10px;
  margin-bottom: 0;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #000 !important;
}
.el-input--small .el-input__inner {
  height: 28px;
}
.star {
  color: red;
}
.leftspace {
  display: flex;
  width: 100%;
  // margin-top: 8px;
  .el-input {
    width: 200px;
    margin-right: 5px;
  }
}
.adio {
  margin-left: 100px;
}
.inpbox {
  margin-left: 100px;
  padding-top: 8px;
}
.group {
  padding-top: 8px;
}
.spans {
  width: 72px;
}
.tabs {
  height: 477px;
  overflow: auto;
  border: 1px solid #dddd;
  margin-left: 10px;
  margin-top: 30px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  border-radius: 5px;
}
.btn {
  margin-left: 20px;
  width: 84px;
  color: #fff;
  border: none;
  background: #4787de;
  cursor: pointer;
  border-radius: 3px;
}
.el-checkbox__label {
  line-height: 13px;
}
.mock {
  line-height: 24px;
}
.breadtitle {
  margin-left: 10px;
  border-bottom: 1px solid #cdd1dd;
  height: 40px;
  line-height: 40px;
  color: #6d7a82;
  font-size: 16px;
}
.el-tabs__item.is-active {
  color: #000;
}
.btnBox {
  margin-top: 20px;
  margin-left: 174px;
  height: 30px;
  padding-bottom: 25px;
}
// 保存确认提示框
#success {
  .el-dialog {
    border-radius: 6px;
    .el-dialog__header {
      padding: 6px;
      background: #1e89e5;
      font-size: 18px;
      text-decoration: none;
      border-radius: 6px 6px 0 0;
      .el-dialog__title {
        color: #fff;
        font-weight: bold;
        font-size: 14px;
      }
      .el-dialog__headerbtn {
        top: 10px !important;
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff !important;
      }
    }
    .el-dialog__body {
      padding: 30px 10px !important;
      text-align: center !important;
    }
    .el-dialog__footer {
      padding: 5px 20px !important;
      background-color: #f5f5f5;
      border-top: 1px solid #ddd;
      border-radius: 0 0 6px 6px;
    }
  }
}
.el-button--primary {
  background-color: #1e89e5 !important;
  border-color: #1e89e5 !important;
}
.el-message-box  {
  width: 240px;
}
.el-message-box__header  {
  background: #1e89e5;
}
.el-message-box__headerbtn  {
  top: 9px;
}
.el-icon-close:before  {
  content: "\e6db";
  color: white;
}
</style>
<style>
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  border-top: 2px solid #1d89e3;
}
.el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
}
.el-tabs__item.is-active {
  color: #000;
}
.el-tabs__item {
  padding: 0 12px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  position: relative;
}
.el-form-item__label {
  padding: 0 0px 0 0 !important;
}
.el-tabs__header {
  margin: 0 0 10px !important;
}
</style>